import React from 'react';

export default function SimpleText({ value }) {
  return (
    <p
      dangerouslySetInnerHTML={{ __html: value }}
      className="leading-normal pb-4"
      suppressHydrationWarning
    />
  );
}
