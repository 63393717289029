/* eslint-disable jsx-a11y/alt-text */
import { Button } from 'antd';
import YoutubePlayerExtra from './Landing/YoutubePlayerExtra';

export default function SimpleClick() {
  return (
    <div className="py-8 flex flex-col bg-orange-50 px-4 md:px-8">
      <h2 className="text-3xl md:text-6xl mt-8 mb-4 text-center px-4 font-bold">
        How to
      </h2>
      <p className="text-center text-sm md:text-2xl">
        Creating a reservation on the OnAir is a breeze, taking just a few
        simple clicks for a swift booking process.
      </p>
      <div className="hidden md:flex justify-center self-center md:my-8">
        <YoutubePlayerExtra
          url="https://youtu.be/KnQs23gW58I"
          width={760}
          height={380}
        />
      </div>
      <div className="flex md:hidden justify-center self-center mb-2 mt-8">
        <YoutubePlayerExtra
          url="https://youtu.be/KnQs23gW58I"
          width={375}
          height={300}
        />
      </div>
      <Button
        size="large"
        data-test-id="park_now"
        type="primary"
        block
        className="rounded-full text-lg md:text-3xl font-semibold hover:bg-opacity-75 w-1/2 md:max-w-xs h-12 md:h-16 self-center mt-4"
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        Book Now
      </Button>
    </div>
  );
}
