/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/alt-text */
import { Carousel } from 'antd';
import { CDN2 } from '@helpers/api';
import Image from 'next/image';
import Link from 'next/link';

interface ITopAirport {
  isMobile?: boolean;
  items?: any[];
}

export default function GuideForTravelling({ isMobile, items }: ITopAirport) {
  return (
    <div className="pb-4 md:pb-8 flex flex-col px-2 xl:px-28">
      <Carousel
        effect={isMobile ? 'fade' : 'scrollx'}
        infinite
        easing="ease"
        slidesToShow={isMobile ? 1 : 3.07}
        slidesToScroll={isMobile ? 1 : 3}
        vertical={false}
        adaptiveHeight={true}
        useCSS
        useTransform
        lazyLoad="progressive"
        className="overflow-hidden md:overflow-visible mt-2 text-center justify-center items-center"
      >
        {items.map((item, index) => {
          return (
            <Link
              key={index}
              href={item.url}
              className="flex flex-col md:flex-row items-center md:items-stretch justify-center my-10 md:mx-3 cursor-pointer"
            >
              <div className="w-[22rem] md:w-96 h-auto pb-4 border rounded-md shadow-md bg-white md:ml-5">
                <div
                  className="relative flex flex-col items-center justify-center w-full h-60 mt-1 bg-no-repeat bg-center rounded-md bg-cover"
                  title={`View ${item.name}`}
                >
                  <Image
                    src={item.image}
                    style={{ borderRadius: '1rem' }}
                    className="z-30 p-2 rounded-md w-full h-60"
                    alt={`View ${item.name}`}
                    width={360}
                    height={240}
                  />
                </div>
                <div className="flex flex-col justify-evenly items-start p-4 md:h-36 ">
                  <h3 className="text-lg font-bold">{item.name}</h3>
                  <div className="flex flex-row items-center self-center mt-2">
                    <p className="text-lg">Read Full Blog</p>
                    <Image
                      src={CDN2() + '/staticmyapp/arrow-up-right.png'}
                      width={20}
                      height={20}
                      alt="icon"
                    />
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </Carousel>
    </div>
  );
}
