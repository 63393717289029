import React from 'react';
import dynamic from 'next/dynamic';

const ReactPlayer = dynamic(() => import('react-player/youtube'), {
  ssr: false,
});

export default function YoutubePlayerExtra({ url, width, height }) {
  return (
    <ReactPlayer
      url={url}
      width={width}
      height={height}
      // className="mx-auto pb-4"
      style={{
        maxWidth: 1318,
        width: width,
        height: height,
        borderRadius: 20,
        overflow: `hidden`,
      }}
    />
  );
}
