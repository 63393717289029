import HighlightIcon from '@/components2/HighlightIcon';
import React, { Fragment } from 'react';

export default function HighlightsExtraB() {
  return (
    <div className="my-2 md:my-4 px-4 md:px-12">
      <div className="flex flex-row justify-center items-center">
        <h2 className="text-3xl md:text-6xl text-center font-bold mb-4">
          Features to help you pick parking
        </h2>
      </div>
      <div
        className={`grid grid-cols-4 gap-y-2 md:gap-y-4 gap-x-6 md:gap-x-0 mt-1 xl:px-20 sm:px-0`}
      >
        <Fragment>
          <div className="flex flex-col justify-center items-center">
            <HighlightIcon
              type="shuttle"
              width={28}
              height={28}
              fill="#434343"
            />

            <p
              className={`text-xs text-center md:text-left flex flex-col justify-center items-center`}
            >
              <span className="font-bold w-16 md:w-auto h-8 md:h-auto">
                Shuttle Frequency
              </span>
            </p>
          </div>

          <div className="flex flex-col justify-center items-center">
            <HighlightIcon
              type="free-shuttle"
              width={28}
              height={28}
              fill="#434343"
            />

            <p
              className={`text-xs text-center md:text-left flex flex-col justify-center items-center`}
            >
              <span className="font-bold w-16 md:w-auto h-8 md:h-auto">
                Free Shuttle
              </span>
            </p>
          </div>

          <div className="flex flex-col justify-center items-center">
            <HighlightIcon
              type="no-free-shuttle"
              width={28}
              height={28}
              fill="#434343"
            />

            <p
              className={`text-xs text-center md:text-left flex flex-col justify-center items-center`}
            >
              <span className="font-bold w-16 md:w-auto h-8 md:h-auto">
                No Shuttle
              </span>
            </p>
          </div>

          <div className="flex flex-col justify-center items-center">
            <HighlightIcon
              type="distance"
              width={28}
              height={28}
              fill="#434343"
            />

            <p
              className={`text-xs text-center md:text-left flex flex-col justify-center items-center`}
            >
              <span className="font-bold w-16 md:w-auto h-8 md:h-auto">
                Distance
              </span>
            </p>
          </div>
        </Fragment>
      </div>
      <div
        className={`grid grid-cols-4 gap-y-2 md:gap-y-4 gap-x-6 md:gap-x-0 mt-4 mb-8 xl:px-20`}
      >
        <Fragment>
          <div className="flex flex-col justify-center items-center">
            <HighlightIcon
              type="accessible"
              width={28}
              height={28}
              fill="#434343"
            />

            <p
              className={`text-xs text-center md:text-left flex flex-col justify-center items-center`}
            >
              <span className="font-bold w-16 md:w-auto h-8 md:h-auto">
                Wheelchair Accessible
              </span>
            </p>
          </div>
          <div className="flex flex-col justify-center items-center">
            <HighlightIcon
              type="free-cancellations"
              width={28}
              height={28}
              fill="#434343"
            />

            <p
              className={`text-xs text-center md:text-left flex flex-col justify-center items-center`}
            >
              <span className="font-bold w-16 md:w-auto h-8 md:h-auto">
                Free Cancellations
              </span>
            </p>
          </div>

          <div className="flex flex-col justify-center items-center">
            <HighlightIcon
              type="open_hour"
              width={28}
              height={28}
              stroke="#434343"
            />

            <p
              className={`text-xs text-center md:text-left flex flex-col justify-center items-center`}
            >
              <span className="font-bold w-16 md:w-auto h-8 md:h-auto">
                Facility Access
              </span>
            </p>
          </div>

          <div className="flex flex-col justify-center items-center">
            <HighlightIcon
              type="eletric_car"
              width={28}
              height={28}
              fill="#434343"
            />

            <p
              className={`text-xs text-center md:text-left flex flex-col justify-center items-center`}
            >
              <span className="font-bold w-16 md:w-auto h-8 md:h-auto">
                EV Charger
              </span>
            </p>
          </div>
        </Fragment>
      </div>
    </div>
  );
}
