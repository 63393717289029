import React from 'react';

export default function EnhancedTextExtra({ value, className }) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: value }}
      className={`${className}`}
    />
  );
}
