/* eslint-disable react/jsx-key */
export default function GoogleNews({ googleNews }) {
  if (!googleNews) return <></>;
  return (
    <section className="h-auto flex flex-col justify-center items-center landing-people-also py-10">
      <h2 className="text-3xl md:text-6xl mt-8 mb-10 text-center px-4 font-bold">
        News
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-5 md:gap-x-8 gap-y-8 px-6 md:px-20 md:mt-12">
        {googleNews.map((news) => (
          <div
            key={news.link._text}
            className="border-2 border-primary/10 flex flex-col rounded-md p-8"
          >
            <p className="text-left mb-2 text-cityList">{news.pubDate._text}</p>
            <p className="text-left mb-2 font-medium md:text-lg">
              {news.title._text}
            </p>
            <p className="font-light">{news.source._text}</p>
            <a
              target="_blank"
              href={news.link._text}
              className="text-secondary mt-2"
            >
              Read More
            </a>
          </div>
        ))}
      </div>
    </section>
  );
}
