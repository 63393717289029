import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGooglePlay, faApple } from '@fortawesome/free-brands-svg-icons';
import { Rate } from 'antd';
import { pushLayerEvent } from '@/utils/gtag';

interface IStoreBtns {
  googleStars: number;
  appleStars: number;
}

export default function StoreBtnsExtraB({ googleStars, appleStars }) {
  return (
    <div className="hidden md:flex flex-col md:flex-row justify-center items-center pt-3">
      <a
        href="https://play.google.com/store/apps/details?id=io.noson.onairparking&pli=1"
        onClick={() => {
          pushLayerEvent('nosonOpenAndroidStore', {});
        }}
        target="_blank"
        rel="noopener noreferrer"
        className="table"
      >
        <div
          className={`bg-[#3D68A5] py-2 px-4 rounded-md flex flex-row items-center w-full text-center font-bold drop-shadow-lg shadow-black`}
        >
          <FontAwesomeIcon
            icon={faGooglePlay}
            className="text-white font-bold text-5xl mr-2"
            style={{ width: 32, height: 38 }}
          />
          <Rate allowHalf disabled defaultValue={googleStars} />
        </div>
        <p
          className={`text-lg w-full text-center mt-4 mb-6 font-bold text-white drop-shadow-lg shadow-black`}
        >
          Google (4.9/5)
        </p>
      </a>
      <a
        href="https://apps.apple.com/us/app/onairparking/id6444409018"
        onClick={() => {
          pushLayerEvent('nosonOpenIosStore', {});
        }}
        target="_blank"
        rel="noopener noreferrer"
        className="table md:ml-16"
      >
        <div
          className={`bg-[#3D68A5] py-2 px-4 rounded-md flex flex-row items-center w-full text-center font-bold drop-shadow-lg shadow-black`}
        >
          <FontAwesomeIcon
            icon={faApple}
            className="text-white font-bold text-5xl mr-2"
            style={{ width: 32, height: 38 }}
          />
          <Rate allowHalf disabled defaultValue={appleStars} />
        </div>
        <p
          className={`text-lg w-full text-center mt-4 mb-6 font-bold text-white drop-shadow-lg shadow-black`}
        >
          Apple (4.9/5)
        </p>
      </a>
    </div>
  );
}
