import React from 'react';
import ReactPlayer from 'react-player/soundcloud';

export default function SoundCloudPlayer({ url, width, height }) {
  return (
    <ReactPlayer
      url={url}
      width="100%"
      height={height}
      className="mx-auto pb-4"
      style={{ maxWidth: 623, width: '100% !important', height: height }}
    />
  );
}
