import React from 'react';
import dynamic from 'next/dynamic';

const ReactPlayer = dynamic(() => import('react-player/youtube'), {
  ssr: false,
});

export default function YoutubePlayer({ url, width, height }) {
  return (
    <ReactPlayer
      url={url}
      width="100%"
      height={height}
      className="mx-auto pb-4"
      style={{ maxWidth: 623, width: '100% !important', height: height }}
    />
  );
}
