import Image from 'next/image';
import SimpleText from '@components/Landing/SimpleText';
import EnhancedText from '@components/Landing/EnhancedText';
import YoutubePlayer from '@/components/Landing/YoutubePlayer';
import SoundCloudPlayer from '@/components/Landing/SoundCloudPlayer';
import { CDN1 } from '@/helpers/api';
import { applyTextSubstitution } from '@/helpers/linkhelper';

const SectionItem = ({
  section,
  secIndex,
  configInfo,
  alt = '',
  fallbackImageAlt = '',
  parameters = { lowestPrice: null },
}) =>
  section?.content
    ?.sort((a, b) => {
      return a.order - b.order;
    })
    .map((item: any, index) => {
      if (item?.type === 'raw')
        return (
          <div
            className="landing-raw"
            key={item.type + index}
            dangerouslySetInnerHTML={{
              __html: applyTextSubstitution(item?.value, parameters)
                ?.replace(/Lato/g, 'Roboto')
                ?.replace(/<br>/g, ''),
            }}
          />
        );
      if (item?.type === 'image')
        return (
          <figure
            className="mx-auto text-center mb-4 figure-landing"
            key={item.type + index}
          >
            <Image
              className="object-contain md:object-scale-down"
              src={`${CDN1}/upload/${
                configInfo.isSandBoxEnvironment
                  ? 1
                  : configInfo.isProductionEnvironment
                  ? 2
                  : 0
              }/${item.value}`}
              width={item.width}
              height={item.height}
              alt={item.alt || alt || fallbackImageAlt}
            />
          </figure>
        );
      if (item?.type === 'table')
        return (
          <div
            className="landing-table"
            dangerouslySetInnerHTML={{
              __html: applyTextSubstitution(item?.value, parameters),
            }}
            key={item.type + index}
          />
        );
      if (item?.type === 'simple_text')
        return (
          <SimpleText
            value={applyTextSubstitution(item.value, parameters)}
            key={item.type + index}
          />
        );
      if (item?.type === 'enhanced_text')
        return (
          <EnhancedText
            value={applyTextSubstitution(item.value, parameters).replace(
              /<br>/g,
              ''
            )}
            key={item.type + '_' + secIndex + '_' + index}
          />
        );
      if (item?.type === 'media_url_youtube')
        return (
          <YoutubePlayer
            url={item.value}
            width={item.width}
            height={item.height}
            key={item.type + index}
          />
        );
      if (item?.type === 'media_url_soundcloud')
        return (
          <SoundCloudPlayer
            url={item.value}
            width={item.width}
            height={item.height}
            key={item.type + index}
          />
        );
    });
export default SectionItem;
