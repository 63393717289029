/* eslint-disable @next/next/no-img-element */
import { Card } from 'antd';
import { CDN2 } from '@helpers/api';
import Image from 'next/image';
import Link from 'next/link';
import dayjs from 'dayjs';

const { Meta } = Card;

interface ITopAirport {
  items?: any[];
}

export default function PeopleAlso({ items }: ITopAirport) {
  return (
    <section className="bg-orange-50 h-auto flex flex-col justify-center items-center landing-people-also py-10">
      <h2 className="text-3xl md:text-6xl mt-8 mb-4 text-center px-4 font-bold">
        {' '}
        People Also Search For
      </h2>
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-6 gap-y-8 my-8">
        {items.map((item, idx) => {
          return (
            <Link href={item.url} key={idx}>
              <Card
                bordered={false}
                className="w-96 md:w-[30rem] bg-orange-50 px-4  cursor-pointer"
              >
                <Image
                  alt={item.name}
                  className="z-30 h-60 w-full rounded-md"
                  src={item.image}
                  width={340}
                  height={240}
                />
                <p className="text-lg md:text-xl text-center md:text-left mb-4">
                  {dayjs(item.date).format('MM-DD-YYYY')}
                </p>
                <Meta
                  title={item.name}
                  className="flex justify-center md:justify-start"
                  description={
                    <div className="flex flex-row items-center justify-center md:justify-start">
                      <p className="text-base md:text-lg">Read Full Blog</p>
                      <Image
                        src={CDN2() + '/staticmyapp/arrow-up-right.png'}
                        width={20}
                        height={20}
                        alt="icon"
                      />
                    </div>
                  }
                />
              </Card>
            </Link>
          );
        })}
      </div>
    </section>
  );
}
