/* eslint-disable jsx-a11y/alt-text */
import { IReviews } from '@/types';
import { Button, Rate } from 'antd';
import dayjs from 'dayjs';

export default function ReviewsLanding({ reviews }: { reviews: IReviews[] }) {
  return (
    <div className="pb-8 flex flex-col">
      <h2 className="text-3xl md:text-6xl mt-8 mb-10 text-center px-4 font-bold">
        Your Feedback, Our Pride
      </h2>
      <div className="grid grid-cols-1 xl:grid-cols-3 md:gap-x-8 gap-y-8 px-4 md:px-6 md:my-12">
        {!reviews ? (
          <>
            <div className="bg-gray-200 flex flex-col items-center rounded-md p-8">
              <p className="text-xs md:text-base text-center mt-6 mb-2">
                A happy customer
              </p>
              <Rate
                disabled
                defaultValue={5}
                className="!text-2xl !md:text-3xl"
              />
              <p className="text-2xl md:text-3xl text-center mt-7 font-bold">
                "Parking Was Easy"
              </p>

              <p className="text-center mt-4 text-xs md:text-base">
                Parking was easy and no confusion. Shuttle was there
                immediately. Good service.
              </p>
            </div>
            <div className="bg-gray-200 flex flex-col items-center rounded-md p-8">
              <p className="text-xs md:text-base text-center mt-6 mb-2">
                A happy customer
              </p>
              <Rate
                disabled
                defaultValue={5}
                className="!text-2xl !md:text-3xl"
              />
              <p className="text-2xl md:text-3xl text-center mt-7 font-bold">
                “Parking was at a good rate”
              </p>

              <p className="text-center mt-4 text-xs md:text-base">
                Parking was at a good rate I did receive a refund for my taxi on
                the return ride. I bummed a ride to airport. The contact at
                Corporate (Ria) was very helpful how- ever in helping me with
                refund.
              </p>
            </div>
            <div className="bg-gray-200 flex flex-col items-center rounded-md p-8">
              <p className="text-xs md:text-base text-center mt-6 mb-2">
                A happy customer
              </p>
              <Rate
                disabled
                defaultValue={5}
                className="!text-2xl !md:text-3xl"
              />
              <p className="text-2xl md:text-3xl text-center mt-7 font-bold">
                “Great Service”
              </p>

              <p className="text-center mt-4 text-xs md:text-base">
                I was picked up at my car as soon as I parked, and made it to
                the airport in just a few minutes. Coming back to the car was
                easy too. Followed the instructions of where to meet the
                shuttle, and it was there within a couple mins of getting to the
                pickup point.
              </p>
            </div>
          </>
        ) : (
          <>
            {reviews.map((review) => (
              <div
                className="bg-gray-200 flex flex-col items-center rounded-md p-8"
                key={review.review_id}
              >
                <p className="text-xl !md:text-2xl text-center mt-6 mb-2 font-bold">
                  {review.member_email}
                </p>
                <Rate
                  disabled
                  defaultValue={review.review_grade}
                  className="!text-xl !md:text-2xl"
                />
                <p className="text-center mt-4 text-sm md:text-base">
                  {review.review_comment}
                </p>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}
